import 'vuetify/styles' // Global CSS has to be imported
import { createApp } from 'vue'
// import { createVuetify } from 'vuetify'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { initializeApp } from 'firebase/app'
// import VueVideoPlayer from '@videojs-player/vue'
// import 'video.js/dist/video-js.css'
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'
import Notifications from '@kyvg/vue3-notification'
import Toast, { PluginOptions } from "vue-toastification";
// Import the CSS or use your own!

import "vue-toastification/dist/index.css";

const firebaseConfig = {
    // apiKey: "AIzaSyBhvLfpM2jhLc5p_wqAlcuUcyYejbzb9-c",
    // authDomain: "fnf-bingo.firebaseapp.com",
    // databaseURL: "https://fnf-bingo-default-rtdb.firebaseio.com",
    // projectId: "fnf-bingo",
    // storageBucket: "fnf-bingo.appspot.com",
    // messagingSenderId: "621835506314",
    // appId: "1:621835506314:web:0b20116afc3bcfd8419373",
    // measurementId: "G-RCRMGN6C7P"

    apiKey: "AIzaSyBJNbtRhVcPQhGCWy1O4JiJFgLsMUpmsCE",
    authDomain: "fnf-bingo-v2.firebaseapp.com",
    databaseURL: "https://fnf-bingo-v2-default-rtdb.firebaseio.com",
    projectId: "fnf-bingo-v2",
    storageBucket: "fnf-bingo-v2.appspot.com",
    messagingSenderId: "939762184787",
    appId: "1:939762184787:web:b2c5b9a31cd9c596b50ca6",
    measurementId: "G-ZMLH9BHLED"

};
initializeApp(firebaseConfig)

loadFonts()
const options = {
  // You can set your default options here
};

createApp(App)
  .use(router)
  .use(vuetify)
  .use(Vue3VideoPlayer)
  .use(Notifications)
  .use(Toast, options)

 
  .use(require('vue-three-shortkey'),{ prevent: ['input', 'textarea'] })
  

  .mount('#app')
