import { createRouter, createWebHistory } from "vue-router";
import { getAuth, onAuthStateChanged } from "firebase/auth";
const router = createRouter({
    history: createWebHistory(),
    routes: [
        // { path: "/", component: () => import("../views/SignIn.vue") },
        { path: "/sign-up", component: () => import("../views/SignUp.vue") },
        { path: "/sign-in", component: () => import("../views/SignIn.vue") },
        {
            path: "/",
            component: () => import("../views/MainScreen.vue"),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/ex",
            component: () => import("../views/ExternalScreen.vue"),
            meta: {
                requiresAuth: true
            }
        },
        {
            path: "/ex-l",
            component: () => import("../views/ExternalScreenLoteria.vue"),
            meta: {
                requiresAuth: true
            }
        },

    ]
})
const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const removeListener = onAuthStateChanged(
            getAuth(),
            (user) => {
                removeListener();
                resolve(user);
            },
            reject)
    })
}
router.beforeEach(async (to, from, next) => {
    if (await getCurrentUser() && (to.path=="/sign-in"|| to.path=="/sign-up")) {
        next("/")
    }
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (await getCurrentUser()) {
            next();
        }
        else {
            // alert("you dont have access")
            next("/sign-in")
        }
    }
    else {
        next();
    }
   
    
    
})
export default router;