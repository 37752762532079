<template>
  <!-- <nav>
    <router-link to="/">Home</router-link>
    <router-link to="/main">Main</router-link>
    <router-link to="/register">Register</router-link>
    <router-link to="/sign-in">Login</router-link>
    <button v-if="isLoggedIn" @click="handleSignOut">Sign Out</button>
  </nav> -->

  <router-view />
</template>

<script setup>
import { onMounted, ref as ref_ } from "vue";
import { getAuth, onAuthStateChanged, 
// signOut
 } from "firebase/auth";
import {
  // getDatabase,
  // ref,
  // set,
  // serverTimestamp,
  // get,
  // child,
  // update,
} from "firebase/database";
// import router from "./router";
const isLoggedIn = ref_(false);
let auth;
onMounted(() => {
  auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      isLoggedIn.value = true;
    } else {
      isLoggedIn.value = false;
    }
  });
});
// const handleSignOut = () => {
//   const uid = getAuth().currentUser.uid;
//   // alert(uid);
//   signOut(auth).then(() => {
//     console.log(auth);
//     const db = getDatabase();

//     set(ref(db, "users/" + uid + "/connections"), null);
//     set(ref(db, "users/" + uid + "/lastOnline"), serverTimestamp());
//     roomClose(uid);
//     router.push("/");
//   });
// };
// const roomClose = (uid) => {
//   const dbRef = ref(getDatabase());
//   get(child(dbRef, `users/` + uid))
//     .then((snapshot) => {
//       if (snapshot.exists()) {
//         const db = getDatabase();

//         const dbRef = ref(db, snapshot.val().locationref);
//         update(dbRef, {
//           claimed: false,
//           : false,
//         })
//           .then(() => {
//             console.log("Room Opened");
//           })
//           .catch((e) => {
//             console.log(e);
//           });
//       } else {
//         console.log("No data available");
//       }
//     })
//     .catch((error) => {
//       console.error(error);
//     });
// };
// export default {
//   name: 'App',

//   data: () => ({
//     //
//   }),
// }
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */
}
::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)!important;
	background-color: #F5F5F5!important;
}

::-webkit-scrollbar
{
	width: 6px!important;
	background-color: #F5F5F5!important;
}

::-webkit-scrollbar-thumb
{
	background-color: #c2c2c2!important;
}
</style>
